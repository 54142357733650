import {useState, useEffect} from 'react'
import Axios from 'axios'
import './App.css';
import axios from 'axios';

function App() {
  const [review, setReview] = useState('');
  const [name, setName]=useState('');
  const [movieReviewList, setMovieList]=useState([])
  const [newReview, setNewReview]=useState('')

  useEffect(() => {
    // Use axios from your own DB
    Axios.get('https://www.express-node.jameshalstead.dev/api/get').then((response) => {
      setMovieList(response.data)
      // ,
      // Test for clear cache
      // axios.Instance.default.headers = {
      //   'Cache-Conrol': 'no-cache',
      //   'Pragma': 'no-cache',
      //   'Expires': '0',
      // }
    });
  }, []);

  const submitReview = () => {
    // using the movieName from the backend and applying the name from the frontend
    Axios.post('https://www.express-node.jameshalstead.dev/api/insert', {
      movieName: name, 
      movieReview: review
    })
      // Spread operator to update list real time without refreshing
      setMovieList([
        ...movieReviewList, 
        { movieName: name, movieReview: review },
      ]);
      setName('')
      setReview('')
  };

  const deleteReviewButton = (movie) => {
    Axios.delete(`https://www.express-node.jameshalstead.dev/api/delete/${movie}`)
  }

  const updateReviewButton = (movie) => {
    Axios.put('https://www.express-node.jameshalstead.dev/api/update', {
      movieName: movie,
      movieReview: newReview,
    });
    setNewReview('')
  }

  return (
    <div>
     <div className="">
       <input type="text" name="movieName" id="movieName" onChange={(e) => {setName(e.target.value)}} placeholder="enter somehting here" />
     </div>
     <div className="">
       <input type="text" name="movieReview" id="movieReview" onChange={(e) => {setReview(e.target.value)}} placeholder="enter somehting here" />
     </div>
     <div >
       <button onClick={submitReview}>Submit</button>
     </div>

     <div>
       {movieReviewList.map((review) => {
         return  <div className='' key={review.movieName}>
           <h2>{review.movieName}</h2>  
           <p>{review.movieReview}</p>

           <button onClick={() => {deleteReviewButton(review.movieName)}}>Delete</button>
           <input type='text' id='updateInput' name='updateInput' onChange={(e) => setNewReview(e.target.value)} />
           <button onClick={() => {updateReviewButton(review.movieName)}}>Update</button>
           </div>   
       })}
     </div>
    </div>
  );
}

export default App;
